import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Segment, Button } from 'semantic-ui-react';
import { averMonthDaysUserInputF } from '../../../../app/common/util/retiredWageCalF';
import MyNumberInput from '../../../../app/common/form/MyNumberInput';
import MyDateInput from '../../../../app/common/form/MyDateInput';
import MyTextInput from '../../../../app/common/form/MyTextInput';
import RetireWageCalcFormItems from './RetireWageCalcFormItems';
import { roundDown } from '../../../../app/common/util/util';
import * as XLSX from 'xlsx';

export default function RetireWageCalcForm() {
	const [userInputData, setUserInputData] = useState({});

	// retiredDate 상태 관리
	const [retiredDate, setRetiredDate] = useState('');

	// retiredDate를 처음 한 번만 설정
	useEffect(() => {
		if (!retiredDate) {
			setRetiredDate(new Date()); // retiredDate가 없으면 현재 날짜로 설정
		}
	}, [retiredDate]);

	// 사용자 입력 기반 계산 로직
	const retireFundTaxFreeValue = userInputData?.retireFundTaxFree || '';

	// averMonthDays 계산
	const averMonthDays = averMonthDaysUserInputF(
		0,
		0,
		retireFundTaxFreeValue,
		userInputData // userInputs로 사용
	);

	// formik의 initialValues
	const initialValues = {
		workerName: '', // workerName 상태값으로 대체
		retiredDate: retiredDate || '', // retiredDate 상태값으로 대체
		workerEnteredDate: userInputData.workerEnteredDate || '',
		totalWorkingDays: roundDown(averMonthDays.totalWorkingDays) || '',
		retiredMonthDay: averMonthDays.retireMonthFirstDateString || '',
		retiredMonthDayEnd: averMonthDays.retireDateString || '',
		retiredMonthDayCount: roundDown(averMonthDays.retireMonthDays) || '',
		retiredMonthDay01: averMonthDays.retireOneMonthBeforeFirstDateString || '',
		retiredMonthDayEnd01: averMonthDays.retireOneMonthBeforeEndDateString || '',
		retiredMonthDayCount01:
			roundDown(averMonthDays.retireOneMonthBeforeMonthDays) || '',
		retiredMonthDay02: averMonthDays.retireTwoMonthBeforeFirstDateString || '',
		retiredMonthDayEnd02: averMonthDays.retireTwoMonthBeforeEndDateString || '',
		retiredMonthDayCount02:
			roundDown(averMonthDays.retireTwoMonthBeforeMonthDays) || '',
		retiredMonthDay03:
			averMonthDays.retireThreeMonthBeforeFirstDateString || '',
		retiredMonthDayEnd03:
			averMonthDays.retireThreeMonthBeforeEndDateString || '',
		retiredMonthDayCount03:
			roundDown(averMonthDays.retireThreeMonthBeforeMonthDays) || '',
		realAverWageOfRetiredMonth: userInputData.realAverWageOfRetiredMonth || '',
		retireMonth1TotalWage: userInputData.retireMonth1TotalWage || '',
		retireMonth2TotalWage: userInputData.retireMonth2TotalWage || '',
		retireMonth3TotalWage: userInputData.retireMonth3TotalWage || '',
		retireYearTotalAnnualLeavesWages:
			userInputData.retireYearTotalAnnualLeavesWages || '',
		retireYearTotalBonusWages: userInputData.retireYearTotalBonusWages || '',
		retireFundTaxFree: userInputData.retireFundTaxFree || '',
		dayOrdinaryWage: userInputData.dayOrdinaryWage || '',
		retireAverageDayWage: averMonthDays.averageFinalDayWage || '',
		finalRetiredFund: averMonthDays.finalRetiredFund || '',
		retiredFundTax: averMonthDays.finalRetiredFundTax || '',
		retiredFundRegionTax: averMonthDays.finalRetiredFundRegionTax || '',
		retireFund: averMonthDays.retireFund || '',
	};

	// validation schema
	const validationSchemaEmployee4In = Yup.object({
		retireAverageDayWage: Yup.string().required('필수항목'),
		retireFund: Yup.string().required('필수항목'),
		finalRetiredFund: Yup.string().required('필수항목'),
	});

	// 필드의 name을 label로 변경하여 Excel로 저장하는 함수
	const exportToExcelWithLabels = (values) => {
		// name 속성과 label 속성 매핑
		const fieldLabels = {
			workerName: '직원이름',
			retiredDate: '마지막 근무일',
			workerEnteredDate: '입사일',
			totalWorkingDays: '총 근무일수',
			retiredMonthDay: '퇴직월 1일',
			retiredMonthDayEnd: '퇴직월 마지막근무일',
			retiredMonthDayCount: '퇴직월 근무일수',
			retiredMonthDay01: '퇴직 전월 1일',
			retiredMonthDayEnd01: '퇴직 전월 말일',
			retiredMonthDayCount01: '퇴직 전월 근무일수',
			retiredMonthDay02: '퇴직 전전월 1일',
			retiredMonthDayEnd02: '퇴직 전전월 말일',
			retiredMonthDayCount02: '퇴직 전전월 근무일수',
			retiredMonthDay03: '퇴직 전전전월 1일',
			retiredMonthDayEnd03: '퇴직 전전전월 말일',
			retiredMonthDayCount03: '퇴직 전전전월 근무일수',
			realAverWageOfRetiredMonth: '퇴직월 평균임금',
			retireMonth1TotalWage: '퇴직 전월 총 임금',
			retireMonth2TotalWage: '퇴직 전전월 총 임금',
			retireMonth3TotalWage: '퇴직 전전전월 총 임금',
			retireYearTotalAnnualLeavesWages: '연차 미사용 수당',
			retireYearTotalBonusWages: '상여금',
			dayOrdinaryWage: '통상임금(일급)',
			retireAverageDayWage: '평균임금(일급)',
			finalRetiredFund: '퇴직금 실수령액',
			retireFundTaxFree: '퇴직비과세액',
			retiredFundTax: '퇴직소득세',
			retiredFundRegionTax: '퇴직지방세',
			retireFund: '법정퇴직금(세전)',
		};

		// label을 기준으로 데이터를 변환
		const labeledData = Object.keys(values).reduce((acc, key) => {
			console.log('key', key);
			acc[fieldLabels[key]] = values[key]; // name 대신 label로 키 값 설정
			return acc;
		}, {});

		// 데이터를 엑셀 시트로 변환
		const worksheet = XLSX.utils.json_to_sheet([labeledData]);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, 'RetireWageData');

		// 엑셀 파일 저장
		XLSX.writeFile(workbook, 'RetireWageData.xlsx');
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchemaEmployee4In}
			onSubmit={async (values, { setSubmitting }) => {
				try {
					console.log('values');
				} catch (error) {
					console.log(error.message);
					toast.error(error.message);
					setSubmitting(false);
				}
			}}
			enableReinitialize={true}>
			{({ isSubmitting, dirty, isValid, values }) => (
				<Form className='ui form'>
					<Segment clearing>
						<RetireWageCalcFormItems
							MyDateInput={MyDateInput}
							MyTextInput={MyTextInput}
							MyNumberInput={MyNumberInput}
							values={values}
							retiredDate={retiredDate}
							setUserInputData={setUserInputData}
							setRetiredDate={setRetiredDate}
						/>
						{/* Excel로 저장하는 버튼 */}
						<Button
							type='button'
							color='blue'
							onClick={() => exportToExcelWithLabels(values)}>
							Excel로 저장
						</Button>
					</Segment>
				</Form>
			)}
		</Formik>
	);
}
