import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown, Menu } from 'semantic-ui-react';

export default function CalculatorMenu({ CiCalculator1, isWorker }) {
	const history = useHistory();

	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 1000);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const handleMouseOver = () => {
		setDropdownOpen(true);
	};

	const handleMouseLeave = () => {
		setDropdownOpen(false);
	};

	const handleDropdownToggle = () => {
		setDropdownOpen(!dropdownOpen);
	};

	function reloadToNetCalc() {
		history.push('/netCalc');
	}

	function reloadToAnnualLeavesCalc() {
		history.push('/annualLeavesCalc');
	}

	function reloadToRetireFundCalc() {
		history.push('/retireFundCalc');
	}

	return (
		<>
			<Menu.Item
				className='menu-item'
				onMouseOver={handleMouseOver}
				onMouseLeave={handleMouseLeave}>
				{!isMobile && <CiCalculator1 className='icon-spacing' />}
				<Dropdown
					pointing='top left'
					text='노동법 계산기'
					open={dropdownOpen}
					onMouseOver={handleMouseOver}
					onMouseLeave={handleMouseLeave}
					onClick={handleDropdownToggle}>
					<Dropdown.Menu>
						<Dropdown.Item
							onClick={() => reloadToNetCalc()}
							text='실수령액 계산기'
						/>
						{isWorker ? null : (
							<>
								<Dropdown.Item
									onClick={() => reloadToAnnualLeavesCalc()}
									text='연차 계산기'
								/>
							</>
						)}
						<Dropdown.Item
							onClick={() => reloadToRetireFundCalc()}
							text='퇴직금 계산기'
						/>
					</Dropdown.Menu>
				</Dropdown>
			</Menu.Item>
		</>
	);
}
