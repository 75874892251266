import React, { useEffect, useMemo } from 'react';
// import MyTextArea from '../../../../app/common/form/MyTextArea';

export default function RetireWageCalcFormItems({
	MyDateInput,
	MyTextInput,
	MyNumberInput,
	values,
	retiredDate,
	setUserInputData,
	setRetiredDate,
}) {
	// userInputDataValue를 useMemo로 캐싱
	const userInputDataValue = useMemo(
		() => ({
			workerName: values.workerName,
			retiredDate: values.retiredDate,
			workerEnteredDate: values.workerEnteredDate,
			totalWorkingDays: values.totalWorkingDays,
			retiredMonthDay: values.retiredMonthDay,
			retiredMonthDayEnd: values.retiredMonthDayEnd,
			retiredMonthDayCount: values.retiredMonthDayCount,
			realAverWageOfRetiredMonth: values.realAverWageOfRetiredMonth,
			retiredMonthDay01: values.retiredMonthDay01,
			retiredMonthDayEnd01: values.retiredMonthDayEnd01,
			retiredMonthDayCount01: values.retiredMonthDayCount01,
			retireMonth1TotalWage: values.retireMonth1TotalWage,
			retiredMonthDay02: values.retiredMonthDay02,
			retiredMonthDayEnd02: values.retiredMonthDayEnd02,
			retiredMonthDayCount02: values.retiredMonthDayCount02,
			retireMonth2TotalWage: values.retireMonth2TotalWage,
			retiredMonthDay03: values.retiredMonthDay03,
			retiredMonthDayEnd03: values.retiredMonthDayEnd03,
			retiredMonthDayCount03: values.retiredMonthDayCount03,
			retireMonth3TotalWage: values.retireMonth3TotalWage,
			retireYearTotalAnnualLeavesWages: values.retireYearTotalAnnualLeavesWages,
			retireYearTotalBonusWages: values.retireYearTotalBonusWages,
			dayOrdinaryWage: values.dayOrdinaryWage,
			retireFundTaxFree: values.retireFundTaxFree,
		}),
		[values]
	);

	useEffect(() => {
		setRetiredDate(userInputDataValue?.retiredDate);
	}, [setRetiredDate, userInputDataValue]);

	useEffect(() => {
		setUserInputData(userInputDataValue);
	}, [setUserInputData, userInputDataValue]);

	return (
		<>
			<hr />
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'flex-start',
					alignItems: 'center',
					alignContent: 'flex-end',
				}}>
				<div
					style={{
						width: '100%',
						marginBottom: '20px',
						marginTop: '10px',
						fontSize: '20px',
					}}>
					✓ 퇴직금 등 평균임금 계산기
				</div>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						alignContent: 'flex-end',
						width: '100%',
						marginBottom: '0px',
						marginTop: '30px',
						fontSize: '17px',
					}}></div>
				<div className='margin' style={{ width: '24.5%', marginRight: '3px' }}>
					<MyTextInput name='workerName' label='직원이름' autoComplete='off' />
				</div>
				<div className='margin' style={{ width: '24.5%', marginRight: '3px' }}>
					<MyDateInput
						label={<span style={{ color: 'blue' }}>* (1) 입사일</span>}
						name='workerEnteredDate'
						placeholderText='달력 사용(또는 0000-00-00  형식으로 입력요망)'
						timeFormat='HH:mm'
						timeCaption='time'
						dateFormat='yyyy-MM-dd'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '24.5%', marginRight: '3px' }}>
					<MyDateInput
						label={
							retiredDate ? (
								<span style={{ color: 'blue' }}>* (2) 마지막 근무일</span>
							) : (
								<span style={{ color: 'blue' }}>
									* (2) 평균임금 산정 사유발생일 전날(예, 마지막 근무일)
								</span>
							)
						}
						name='retiredDate'
						placeholderText='달력 사용(또는 0000-00-00  형식으로 입력요망)'
						timeFormat='HH:mm'
						timeCaption='time'
						dateFormat='yyyy-MM-dd'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '24.5%', marginRight: '3px' }}>
					<MyNumberInput
						label={'총 근무일수'}
						name='totalWorkingDays'
						placeholder='최소 재직일수는 365일 입니다.'
						autoComplete='off'
						disabled
					/>
				</div>
				<div className='margin' style={{ width: '24.5%', marginRight: '3px' }}>
					<MyDateInput
						name='retiredMonthDay'
						label={'퇴직월 1일'}
						timeCaption='time'
						dateFormat='yyyy-MM-dd'
						autoComplete='off'
						disabled
					/>
				</div>
				<div className='margin' style={{ width: '24.5%', marginRight: '3px' }}>
					<MyDateInput
						name='retiredMonthDayEnd'
						label={'퇴직월 마지막근무일'}
						timeCaption='time'
						dateFormat='yyyy-MM-dd'
						autoComplete='off'
						disabled
					/>
				</div>
				<div className='margin' style={{ width: '24.5%', marginRight: '3px' }}>
					<MyNumberInput
						label='일수'
						name='retiredMonthDayCount'
						autoComplete='off'
						disabled
					/>
				</div>
				<div className='margin' style={{ width: '24.5%', marginRight: '3px' }}>
					<MyNumberInput
						label='평균임금'
						name='realAverWageOfRetiredMonth'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '24.5%', marginRight: '3px' }}>
					<MyDateInput
						name='retiredMonthDay01'
						label={'퇴직 전월 1일'}
						timeCaption='time'
						dateFormat='yyyy-MM-dd'
						autoComplete='off'
						disabled
					/>
				</div>
				<div className='margin' style={{ width: '24.5%', marginRight: '3px' }}>
					<MyDateInput
						name='retiredMonthDayEnd01'
						label={'퇴직전월 말일'}
						timeCaption='time'
						dateFormat='yyyy-MM-dd'
						autoComplete='off'
						disabled
					/>
				</div>
				<div className='margin' style={{ width: '24.5%', marginRight: '3px' }}>
					<MyNumberInput
						label='일수'
						name='retiredMonthDayCount01'
						autoComplete='off'
						disabled
					/>
				</div>
				<div className='margin' style={{ width: '24.5%', marginRight: '3px' }}>
					<MyNumberInput
						label='평균임금'
						name='retireMonth1TotalWage'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '24.5%', marginRight: '3px' }}>
					<MyDateInput
						name='retiredMonthDay02'
						label={'퇴직 전전월 1일'}
						timeCaption='time'
						dateFormat='yyyy-MM-dd'
						autoComplete='off'
						disabled
					/>
				</div>
				<div className='margin' style={{ width: '24.5%', marginRight: '3px' }}>
					<MyDateInput
						name='retiredMonthDayEnd02'
						label={'퇴직 전전월 말일'}
						timeCaption='time'
						dateFormat='yyyy-MM-dd'
						autoComplete='off'
						disabled
					/>
				</div>
				<div className='margin' style={{ width: '24.5%', marginRight: '3px' }}>
					<MyNumberInput
						label='일수'
						name='retiredMonthDayCount02'
						autoComplete='off'
						disabled
					/>
				</div>
				<div className='margin' style={{ width: '24.5%', marginRight: '3px' }}>
					<MyNumberInput
						label='평균임금'
						name='retireMonth2TotalWage'
						autoComplete='off'
					/>
				</div>

				<>
					<div
						className='margin'
						style={{ width: '24.5%', marginRight: '3px' }}>
						<MyDateInput
							name='retiredMonthDay03'
							label={'퇴직 전전전월 초일'}
							timeCaption='time'
							dateFormat='yyyy-MM-dd'
							autoComplete='off'
							disabled
						/>
					</div>
					<div
						className='margin'
						style={{ width: '24.5%', marginRight: '3px' }}>
						<MyDateInput
							name='retiredMonthDayEnd03'
							label={'퇴직 전전전월 말일'}
							timeCaption='time'
							dateFormat='yyyy-MM-dd'
							autoComplete='off'
							disabled
						/>
					</div>
					<div
						className='margin'
						style={{ width: '24.5%', marginRight: '3px' }}>
						<MyNumberInput
							label='일수'
							name='retiredMonthDayCount03'
							autoComplete='off'
							disabled
						/>
					</div>

					<div
						className='margin'
						style={{ width: '24.5%', marginRight: '3px' }}>
						<MyNumberInput
							label='평균임금'
							name='retireMonth3TotalWage'
							autoComplete='off'
						/>
					</div>
				</>
			</div>
			<hr />
			<p style={{ color: 'blueviolet' }}>
				* 년간 지급된 평균임금(해당 금액 / 12개월 * 3개월)
			</p>
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'flex-start',
					alignItems: 'center',
					alignContent: 'flex-end',
				}}>
				<div className='margin' style={{ width: '49.5%', marginRight: '3px' }}>
					<MyNumberInput
						label='전전년도 발생 연차 중 전년도에 사용하지 못한 연차 미사용 수당'
						name='retireYearTotalAnnualLeavesWages'
						placeholder='올해 발생한 년차 중 미사용연차는 평균임금 산입불가'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '49.5%', marginRight: '3px' }}>
					<MyNumberInput
						label='최근 1년간 받은 상여금'
						name='retireYearTotalBonusWages'
						placeholder='최근 1년간 받은 상여금'
						autoComplete='off'
					/>
				</div>
			</div>
			<hr />
			<p style={{ color: 'blue' }}>
				* 법정 퇴직금(통상임금이 평균임금보다 높은 경우 통상임금으로 계산)
			</p>
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'flex-start',
					alignItems: 'center',
					alignContent: 'flex-end',
				}}>
				<div className='margin' style={{ width: '24.5%', marginRight: '3px' }}>
					<MyNumberInput
						label='통상임금(일급)'
						name='dayOrdinaryWage'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '24.5%', marginRight: '3px' }}>
					<MyNumberInput
						label='평균임금(일급)'
						name='retireAverageDayWage'
						autoComplete='off'
						disabled
					/>
				</div>
				{retiredDate ? (
					<div
						className='margin'
						style={{ width: '24.5%', marginRight: '3px' }}>
						<MyNumberInput
							label='법정퇴직금(세전)'
							name='retireFund'
							autoComplete='off'
							disabled
						/>
					</div>
				) : null}
			</div>
			{retiredDate ? (
				<>
					<hr />
					<p style={{ color: 'blue' }}>
						* 퇴직소득세 계산(퇴직비과세소득 등 정확한 세금은 세무사의 확인을
						받으세요.)
					</p>
					<div
						style={{
							display: 'flex',
							flexWrap: 'wrap',
							justifyContent: 'flex-start',
							alignItems: 'center',
							alignContent: 'flex-end',
						}}>
						<div
							className='margin'
							style={{ width: '24.5%', marginRight: '3px' }}>
							<MyNumberInput
								label='퇴직비과세'
								name='retireFundTaxFree'
								autoComplete='off'
							/>
						</div>
						<div
							className='margin'
							style={{ width: '24.5%', marginRight: '3px' }}>
							<MyNumberInput
								label='퇴직소득세'
								name='retiredFundTax'
								autoComplete='off'
								disabled
							/>
						</div>
						<div
							className='margin'
							style={{ width: '24.5%', marginRight: '3px' }}>
							<MyNumberInput
								label='퇴직지방세'
								name='retiredFundRegionTax'
								autoComplete='off'
								disabled
							/>
						</div>
						<div
							className='margin'
							style={{ width: '24.5%', marginRight: '3px' }}>
							<MyNumberInput
								label='퇴직금 실수령액'
								name='finalRetiredFund'
								autoComplete='off'
								disabled
							/>
						</div>
					</div>
					<br />
				</>
			) : null}
		</>
	);
}
